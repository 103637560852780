<template>
    <v-container class="px-0">
        <Message/>
        <v-row justify="center">
            <v-col cols="12" md="4" class="font-weight-black"><h3><v-icon @click="switchToList()">arrow_back</v-icon>{{this.quizObj.quizTitle}}</h3></v-col>
        </v-row>
        <v-data-table           
            :headers="headersQuestions"
            :items="itemsQuestions"                     
        >
            <template v-slot:top>
                <v-toolbar
                    flat
                >         
                    <v-dialog
                        v-model="dialog"
                        max-width="900"                      
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                dark
                                class="text-capitalize mb-2"
                                v-bind="attrs"
                                v-on="on"               
                                absolute
                                right
                            >
                                {{$t('newQuestion')}}
                            </v-btn>
                        </template>
                        <v-card>
                            <Loading/>
                            <VideoRecorder v-if="webCam"/>
                            <AudioRecorder v-if="audioCam"/>
                            <video-player v-if="chkUploadedVideoForView==true" :options="videoOptions" />
                            <v-btn v-if="chkUploadedVideoForView==true"  outlined class="text-capitalize ma-1" @click="showRecording()">{{ $t('newUpload') }}</v-btn>
                            <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-container>
                                    <v-form ref="form">
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                sm="12" 
                                                md="8"                                                                                            
                                            >
                                                <v-textarea
                                                    v-model="editedItem.questionDetail"
                                                    :label="$t('question')"
                                                    :rules="[rules.required]"
                                                    rows="1"
                                                    clearable                       
                                                ></v-textarea>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="2"
                                            >
                                                <v-checkbox
                                                    v-model="editedItem.isCodeSnippet"
                                                    :label="$t('codeSnippet')"                                                                                                   
                                                    clearable                       
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="2"
                                                v-if="chkPerQuestionMarkVisibility==true"
                                            >
                                                <v-text-field
                                                    v-model="editedItem.perQuestionMark"
                                                    :rules="[rules.required,rules.minimumVal]" 
                                                    type="number"                                                  
                                                    :label="$t('marks')"                                                                                                   
                                                    clearable                       
                                                ></v-text-field>
                                            </v-col>                                                                                                                                                                                     
                                        </v-row>                                    
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                            >
                                                <v-select
                                                    v-model="typeOptionSelect"
                                                    :label="$t('questionType')"
                                                    :items="typeOptionItems"
                                                    item-text="questionTypeName"
                                                    item-value="questionTypeId"
                                                    :rules="[rules.required]"
                                                    v-on:change="chkQuestionType"                                                                                              
                                                    clearable
                                                    return-object                       
                                                ></v-select> 
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                            >
                                                <v-select
                                                    v-model="typeAnswerSelect"
                                                    :label="$t('answerTypeName')"
                                                    :items="typeAnswerItems"
                                                    item-text="questionTypeName"
                                                    item-value="questionTypeId"
                                                    :rules="[rules.required]"
                                                    v-on:change="chkAnswerType"                                                                                              
                                                    clearable
                                                    return-object                       
                                                ></v-select> 
                                            </v-col>   
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                                v-if="chkAnswerLengthVisible"
                                            >
                                                <v-text-field
                                                    v-model="editedItem.webCamVideoLength"
                                                    :rules="numberRules" 
                                                    type="number"                                                  
                                                    :label="$t('webCamVideoLenghtmin')"                                                                                                   
                                                    clearable                       
                                                ></v-text-field>
                                            </v-col>                                                                                                                                                                                                                                                   
                                        </v-row>
                                        <v-row v-if="chkimageOptionVisible">
                                            <v-col
                                                cols="12"  
                                                sm="6"               
                                                md="4"
                                            >
                                                <v-file-input
                                                    v-model="imageFile"
                                                    accept="image/*"
                                                    :label="$t('questionImage')"
                                                    prepend-icon="mdi-camera"
                                                    @change="onFileSelected"                       
                                                    show-size
                                                >
                                                </v-file-input>                    
                                            </v-col>
                                            <v-col
                                                cols="12"  
                                                sm="6"                 
                                                md="4"
                                            >
                                                <v-img                       
                                                    :src="previewImage"
                                                    max-height="100"
                                                    max-width="150"
                                                    contain                      
                                                >
                                                </v-img>
                                            </v-col> 
                                        </v-row>  
                                        <v-row
                                            v-if="chkAllOptionsVisible"
                                        >                                           
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                            >
                                                <v-text-field
                                                    v-model="editedItem.optionA"
                                                    label="Option-A"
                                                    :rules="[rules.required]"                                                  
                                                    clearable                       
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                            >
                                                <v-text-field
                                                    v-model="editedItem.optionB"
                                                    label="Option-B"
                                                    :rules="[rules.required]"                                                  
                                                    clearable                       
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                            >
                                                <v-text-field
                                                    v-model="editedItem.optionC"
                                                    label="Option-C(Optional)"                                                                                                    
                                                    clearable                       
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row
                                            v-if="chkAllOptionsVisible"
                                        >
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                            >
                                                <v-text-field
                                                    v-model="editedItem.optionD"
                                                    label="Option-D(Optional)"                                                                                                    
                                                    clearable                       
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                            >
                                                <v-text-field
                                                    v-model="editedItem.optionE"
                                                    label="Option-E(Optional)"                                                                                                    
                                                    clearable                       
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                                v-if="chkAnswerSingleVisibility==true"
                                            >
                                                <v-select
                                                    v-model="editedItem.correctOption"
                                                    :label="$t('correctAnswer')"
                                                    :items="options"
                                                    :rules="[rules.required]"                                                                                                      
                                                    clearable
                                                >                                                   
                                                </v-select>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                                v-if="chkAnswerMultipleVisibility==true"
                                            >
                                                <v-select
                                                    v-model="editedItem.correctOption"
                                                    :label="$t('correctAnswer')"
                                                    :items="options"
                                                    :rules="[rules.required]"                                                   
                                                    multiple
                                                    clearable
                                                >                                                   
                                                </v-select>
                                            </v-col>
                                            
                                        </v-row>                                    
                                        <v-row>
                                            <v-col
                                                cols="12"
                                            >
                                                <v-textarea
                                                    v-model="editedItem.answerExplanation"
                                                    :label="$t('answerExplanation')"
                                                    rows="1"
                                                    clearable                       
                                                ></v-textarea>
                                            </v-col>
                                        </v-row>                                      
                                    </v-form>                
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                >
                                {{$t('cancel')}}
                                </v-btn>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                >
                                {{$t('save')}}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="headline">{{$t('deleteConfirmMessage')}}</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">{{$t('cancel')}}</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{$t('ok')}}</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{item}">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    class="mr-2"
                    @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:[`item.perQuestionMark`]="{item}">
                <span>{{formatMarks(item)}}</span> 
            </template>
            <template v-slot:[`item.webCamVideoLength`]="{item}">
                <span>{{formatAllowedTime(item)}}</span> 
            </template>
            <template v-slot:[`item.correctOption`]="{item}">
                <span>{{formatCorrectOption(item)}}</span> 
            </template>
        </v-data-table>    
    </v-container>
</template>
<script>
import Message from '../../components/common/Message'
import Loading from '../../components/common/Loading'
import AudioRecorder from '../../components/player/AudioRecorder'
import VideoRecorder from '../../components/player/VideoRecorder'
import VideoPlayer from '../../components/player/VideoPlayer.vue'
import config from '../../../public/config'

export default{
    name:'quizQuestions',
    components:{
      Message,
      Loading,
      VideoRecorder,
      AudioRecorder,
      VideoPlayer
    },
    data(){
        return{
            videoOptions: {
                autoplay: false,
                controls: true,
                sources: [
                    {
                        src:{},
                        type: 'video/mp4'
                    }
                ]
            },
            allOptionsVisible:true,
            isShowUploadedVideo:false,
            webCam:false,
            audioCam:false,
            imageOptionVisible:false,
            typeOptionSelect: null,
            typeOptionItems:[],
            typeAnswerSelect: null,
            typeAnswerItems:[],
            typeAnswerItemsInitial:[],
            isAnswerLengthVisible:false,    
            isCodeSnipetVisible:false,       
            previewImage:null,
            previewUploadVideo:null,
            imageFile:null,
            videoFile:null,
            selectedFile:null,
            quizObj:{},
            dialog:false,
            dialogDelete:false,
            options:['Option-A','Option-B','Option-C','Option-D','Option-E'],
            rules:{
                required:value=>!!value||this.$t('required'),
                minimumVal:value=>value>0
            },
            numberRules: [
                v => String(v).length > 0 || this.$t('thisFieldMaynotbeEmpty'),
                v => Number.isInteger(Number(v)) || this.$t('theValueMustbeanIntegerNumber'),
                v => v > 0 || this.$t('theValueMustBeGreaterThanZero')
            ],
            headersQuestions:[
                {text:this.$t('questions'),value:'questionDetail'},
                {text:this.$t('marks'),value:'perQuestionMark'},
                {text:this.$t('questionTypeName'),value:'questionTypeName'},
                {text:this.$t('answerTypeName'),value:'answerTypeName'},
                {text:this.$t('allowedTime'),value:'webCamVideoLength'},
                {text:'Option-A',value:'optionA'},
                {text:'Option-B',value:'optionB'},
                /* {text:'Option-C',value:'optionC'},
                {text:'Option-D',value:'optionD'}, */
                {text:this.$t('correctAnswer'),value:'correctOption'},
                {text:this.$t('actions'), value: 'actions',sortable: false},
            ],
            itemsQuestions:[],
            editedIndex:-1,
            editedItem:{
                quizQuestionId:null,
                quizTopicId:null,               
                questionDetail:'',
                perQuestionMark:0,
                questionTypeId:null,
                answerTypeId:null,
                questionLavelId:null,
                questionCategoryId:null,
                optionA:'',
                optionB:'',
                optionC:'',
                optionD:'',
                optionE:'',
                correctOption:'',
                correctOptionText:'',
                answerExplanation:'',
                imagePath:'',
                videoPath:'',
                webCamVideoLength:0,
                isCodeSnippet:false,                
            },
            defaultItem:{
                quizQuestionId:null,
                quizTopicId:null,
                questionDetail:'',
                perQuestionMark:0,
                questionTypeId:null,
                answerTypeId:null,
                questionLavelId:null,
                questionCategoryId:null,
                optionA:'',
                optionB:'',
                optionC:'',
                optionD:'',
                optionE:'',
                correctOption:'',
                correctOptionText:'',
                answerExplanation:'',
                imagePath:'',
                videoPath:'',
                webCamVideoLength:0,
                isCodeSnippet:false,              
            },
        }        
    },
    methods:{
        showRecording(){
            if(this.typeOptionSelect.questionTypeId==2){
                this.isShowUploadedVideo=false
                this.audioCam=true
            }else if(this.typeOptionSelect.questionTypeId==3){
                this.isShowUploadedVideo=false
                this.webCam=true
            }else{
                this.isShowUploadedVideo=false
                this.audioCam=false
                this.webCam=false
            }          
        },
        formatMarks(item){
            return item.perQuestionMark==0?'':item.perQuestionMark
        },
        formatAllowedTime(item){
            return item.answerTypeId==2 || item.answerTypeId==3?item.webCamVideoLength:''
        },
        formatCorrectOption(item){
            return item.correctOption!=''?item.correctOption.replace(/#####/g,','):''
        },
        onFileSelected(e){           
            if(e!=null){
                this.selectedFile=e
                const reader=new FileReader()
                reader.readAsDataURL(this.selectedFile)
                reader.onload=e=>{
                    this.previewImage=e.target.result
                }
            }else{               
                this.selectedFile=null
                this.previewImage=null
                this.editedItem.imagePath=''
            }                      
        },
        switchToList(){
            this.$router.push({name:'QuizTopics'})
        },
        chkAnswerType(obj){
            if(obj==null){
                this.isAnswerLengthVisible=false                     
            }else if(obj.questionTypeId==2 || obj.questionTypeId==3){
                this.isAnswerLengthVisible=true                
            }
            else{
                this.isAnswerLengthVisible=false
            }
        },
        chkQuestionType(obj){
            if(obj==null){
                this.webCam=false
                this.audioCam=false
                this.imageOptionVisible=false              
                this.isShowUploadedVideo=false
                this.allOptionsVisible=false
            }else if(obj.questionTypeId==1){
                this.imageOptionVisible=true
                this.audioCam=false
                this.webCam=false
                this.isShowUploadedVideo=false
                this.allOptionsVisible=false
            }else if(obj.questionTypeId==2 && this.editedIndex==-1){
                this.audioCam=true
                this.webCam=false
                this.imageOptionVisible=false              
                this.isShowUploadedVideo=false
                this.allOptionsVisible=false
            }else if(obj.questionTypeId==2 && this.editedIndex>-1){
                this.imageOptionVisible=false
                this.audioCam=false
                this.webCam=false
                this.isShowUploadedVideo=true
                this.allOptionsVisible=false
            }else if(obj.questionTypeId==3 && this.editedIndex==-1){
                this.imageOptionVisible=false
                this.audioCam=false
                this.webCam=true
                this.isShowUploadedVideo=false
                this.allOptionsVisible=false
            }else if(obj.questionTypeId==3 && this.editedIndex>-1){
                this.imageOptionVisible=false
                this.audioCam=false
                this.webCam=false
                this.isShowUploadedVideo=true
                this.allOptionsVisible=false
            }else if(obj.questionTypeId==4){
                this.imageOptionVisible=false
                this.audioCam=false
                this.webCam=false
                this.isShowUploadedVideo=false
                this.allOptionsVisible=false
            }
            else if(obj.questionTypeId==5){
                this.allOptionsVisible=true
                this.imageOptionVisible=false
                this.audioCam=false
                this.webCam=false
                this.isShowUploadedVideo=false
            }

            if(obj==null){
                this.typeAnswerItems=this.typeAnswerItemsInitial
            }else if(obj.questionTypeId==5){
                this.typeAnswerItems=this.typeAnswerItemsInitial.filter(function(item){
                    return item.questionTypeName.includes('MCQ')==true
                })
            }else{
                this.typeAnswerItems=this.typeAnswerItemsInitial.filter(function(item){
                    return item.questionTypeName.includes('MCQ')==false
                })
            }
        },
        getTypeOptions(){
            this.$store.dispatch('quiz/fetchTypeOptions')
            .then((response)=>{
                this.typeOptionItems=response.data
                this.typeAnswerItemsInitial=response.data.filter(function(item){
                    return item.questionTypeName.includes('Image')==false
                })
            })
            .catch((err)=>{
                this.$router.push({name:'NoInternet'})
                console.log(err)
            })
        },
        initialize(){
            this.isShowUploadedVideo=false
            this.webCam=false
            this.audioCam=false
            this.$store.dispatch('question/storeWebCamPath','')
            this.quizObj=this.$store.getters['question/quiz']
            this.userInfo=this.$store.getters['dashboard/userInfo']  
            //console.log(this.quizObj)
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('question/fetchQuizQuestions',this.quizObj.quizTopicId)
            .then((response)=>{
                //console.log('all',response.data)
                this.$store.dispatch('dashboard/cancelLoading')
                this.itemsQuestions=response.data
            })
            .catch((err)=>{
                this.$router.push({name:'NoInternet'})
                console.log(err)
            })
        },
        selectOption(val,item){
            if(val==item.optionA){
                this.editedItem.correctOption='Option-A'
            }else if(val==item.optionB){
                this.editedItem.correctOption='Option-B'
            }else if(val==item.optionC){
                this.editedItem.correctOption='Option-C'
            }else if(val==item.optionD){
                this.editedItem.correctOption='Option-D'
            }else if(val==item.optionE){
                this.editedItem.correctOption='Option-E'
            }
        },
        selectCorrectAnswer(item){ 
            //console.log(this.quizObj)          
            if(this.quizObj.quizMarkOptionId!=2&&this.quizObj.allowMultipleAnswer==false){
                this.selectOption(item.correctOption,item)               
            }else if(this.quizObj.quizMarkOptionId!=2&&this.quizObj.allowMultipleAnswer==true){
                var allOptions=item.correctOption+'#####'
                var seperatedOptions=allOptions.split('#####')
                var selections=''
                this.editedItem.correctOption=''
                for(var i=0;i<seperatedOptions.length-1;i++){
                    this.selectOption(seperatedOptions[i],item)
                    selections+=this.editedItem.correctOption+'#####'
                }
                this.editedItem.correctOption=selections.split('#####')
            }
        },
        editItem(item){
            //console.log(item)
            this.$store.dispatch('question/storeWebCamPath',item.webCamVideoPath)
            this.previewImage=item.imagePath!=''?config.hostname+item.imagePath:''
            if(item.webCamVideoPath!=''){
                this.webCam=false
                this.audioCam=false
                this.isShowUploadedVideo=true
                this.previewUploadVideo=item.webCamVideoPath!=''?config.hostname+item.webCamVideoPath:''
                this.videoOptions.sources={src:this.previewUploadVideo}
            }
            else{
                this.isShowUploadedVideo=false
            }
            this.editedIndex=this.itemsQuestions.indexOf(item)
            this.editedItem=Object.assign({},item)
            this.typeOptionSelect={questionTypeId:item.questionTypeId}
            this.typeAnswerSelect={questionTypeId:item.answerTypeId}
            this.chkQuestionType(this.typeOptionSelect)     
            this.chkAnswerType(this.typeAnswerSelect)   
            this.selectCorrectAnswer(item)             
            this.dialog=true
        },
        deleteItem(item){
            this.editedItem = Object.assign({}, item)
            this.dialogDelete=true
        },
        deleteItemConfirm () {       
            /* this.$store.dispatch('question/deleteQuestion',this.editedItem.quizQuestionId)
            .then(response=>{
                if(response.status==200){
                    this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                    this.$store.dispatch('question/fetchQuizQuestions',this.quizObj.quizTopicId)
                    .then((response)=>{
                        this.itemsQuestions=response.data                  
                    })
                    .catch((err)=>{
                        console.log(err)
                    })
                }else if(response.status==202){
                    this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                }
            })
            .catch(err=>{
                this.$router.push({name:'NoInternet'})
                console.log(err)
            }) */
            this.$root.$emit('message_from_parent','This operation not allowed in demo version!')
            this.closeDelete()
        },
        close(){
            this.dialog=false
            this.$store.dispatch('question/storeWebCamPath','')
            this.$nextTick(()=>{    
                this.typeOptionSelect=null
                this.typeAnswerSelect=null
                this.imageFile=null
                this.selectedFile=null
                this.previewImage=null  
                this.videoFile=null  
                this.webCam=false
                this.audioCam=false
                this.isShowUploadedVideo=false
                this.editedItem=Object.assign({},this.defaultItem)
                this.editedIndex=-1
            })
        },
        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        setCurrentAnswerSingle(option){
            if(option=='Option-A'){
                this.editedItem.correctOptionText=this.editedItem.optionA!=null?this.editedItem.optionA:''
            }else if(option=='Option-B'){
                this.editedItem.correctOptionText=this.editedItem.optionB!=null?this.editedItem.optionB:''
            }else if(option=='Option-C'){
                this.editedItem.correctOptionText=this.editedItem.optionC!=null?this.editedItem.optionC:''
            }else if(option=='Option-D'){
                this.editedItem.correctOptionText=this.editedItem.optionD!=null?this.editedItem.optionD:''
            }else if(option=='Option-E'){
                this.editedItem.correctOptionText=this.editedItem.optionE!=null?this.editedItem.optionE:''
            }
        },
        setCurrentAnswerMultiple(options){ 
            var answers=''
            this.editedItem.correctOptionText=''   
            for(var i=0;i<options.length;i++){
                this.setCurrentAnswerSingle(options[i])
                if(options[i]!='' && this.editedItem.correctOptionText!=null && this.editedItem.correctOptionText!=''){
                    answers+=this.editedItem.correctOptionText+'#####'
                }
            }
            this.editedItem.correctOptionText=answers.trim().slice(0,-5)
        },
        insertOrUpdateQuestion(){
            if(this.typeOptionSelect.questionTypeId==5&&this.quizObj.quizMarkOptionId!=2&&this.quizObj.allowMultipleAnswer==false){
                this.setCurrentAnswerSingle(this.editedItem.correctOption)
            }else if(this.typeOptionSelect.questionTypeId==5&&this.quizObj.quizMarkOptionId!=2&&this.quizObj.allowMultipleAnswer==true){
                this.setCurrentAnswerMultiple(this.editedItem.correctOption)
            }
            const objQuestion={
                quizTopicId:this.quizObj.quizTopicId,
                questionDetail:this.editedItem.questionDetail.trim(),
                questionTypeId:this.typeOptionSelect.questionTypeId,
                answerTypeId:this.typeAnswerSelect.questionTypeId,
                optionA:this.typeOptionSelect.questionTypeId==5?this.editedItem.optionA:'',
                optionB:this.typeOptionSelect.questionTypeId==5?this.editedItem.optionB:'',
                optionC:this.typeOptionSelect.questionTypeId==5?this.editedItem.optionC:'',
                optionD:this.typeOptionSelect.questionTypeId==5?this.editedItem.optionD:'',
                optionE:this.typeOptionSelect.questionTypeId==5?this.editedItem.optionE:'',
                correctOption:this.typeOptionSelect.questionTypeId==5?this.editedItem.correctOptionText:'',
                answerExplanation:this.editedItem.answerExplanation,
                imagePath:this.editedItem.imagePath,
                isCodeSnippet:this.editedItem.isCodeSnippet,
                webCamVideoPath:this.typeOptionSelect.questionTypeId==2 || this.typeOptionSelect.questionTypeId==3?this.$store.getters['question/webCamVideoPath']:'',
                webCamVideoLength:this.typeAnswerSelect.questionTypeId==2 || this.typeAnswerSelect.questionTypeId==3?this.editedItem.webCamVideoLength:0,
                addedBy:parseInt(localStorage.getItem('loggedUserId')),
            }
            //console.log(objQuestion)
            //console.log('correctOptionText',this.editedItem.correctOptionText.length)
            if(this.typeOptionSelect.questionTypeId==5&&this.quizObj.quizMarkOptionId!=2&&(this.editedItem.correctOptionText=='' || this.editedItem.correctOptionText==null)){
                this.$root.$emit('message_from_parent_long',this.$t('emptyCorrectAnswer'))
            }else if(this.typeOptionSelect.questionTypeId==3 && this.$store.getters['question/webCamVideoPath']==''){
                this.$root.$emit('message_from_parent_long',this.$t('noVideo'))
            }else if(this.typeOptionSelect.questionTypeId==2 && this.$store.getters['question/webCamVideoPath']==''){
                this.$root.$emit('message_from_parent_long',this.$t('noAudio'))
            }else if(this.typeOptionSelect.questionTypeId==2 && this.$store.getters['question/webCamVideoPath'].includes('.oga')==false){
                this.$root.$emit('message_from_parent_long',this.$t('pleaseRecordAudio'))
            }else if(this.typeOptionSelect.questionTypeId==3 && this.$store.getters['question/webCamVideoPath'].includes('.webm')==false){
                this.$root.$emit('message_from_parent_long',this.$t('pleaseRecordVideo'))
            }else if(this.typeOptionSelect.questionTypeId==1 && this.editedItem.imagePath==''){
                this.$root.$emit('message_from_parent_long',this.$t('noImage'))
            }
            else{
                this.$store.dispatch('dashboard/applyLoading')
                if (this.editedIndex > -1){
                    objQuestion.quizQuestionId=this.editedItem.quizQuestionId
                    objQuestion.lastUpdatedBy=parseInt(localStorage.getItem('loggedUserId'))
                    if(this.quizObj.quizMarkOptionId==3){
                        objQuestion.perQuestionMark=this.editedItem.perQuestionMark
                    }

                    this.$store.dispatch('question/updateQuestion',objQuestion)
                    .then(response=>{
                        this.$store.dispatch('dashboard/cancelLoading') 
                        if(response.status==200){
                            this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                            this.$store.dispatch('question/storeWebCamPath','')
                            this.$store.dispatch('question/fetchQuizQuestions',this.quizObj.quizTopicId)
                            .then((response)=>{
                                this.close()
                                this.itemsQuestions=response.data                 
                            })
                            .catch((err)=>{
                                console.log(err)
                            })                      
                        }else if(response.status==202){
                            this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                            }
                    })
                    .catch(err=>{
                        this.$router.push({name:'NoInternet'})
                        console.log(err)
                    })

                }else{
                    if(this.quizObj.quizMarkOptionId==3){
                        objQuestion.perQuestionMark=this.editedItem.perQuestionMark
                    }
                    this.$store.dispatch('question/createQuestion',objQuestion)
                    .then(response=>{
                        this.$store.dispatch('dashboard/cancelLoading') 
                        if(response.status==200){
                            this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                            this.$store.dispatch('question/storeWebCamPath','')
                            this.$store.dispatch('question/fetchQuizQuestions',this.quizObj.quizTopicId)
                            .then((response)=>{
                                this.close()
                                this.itemsQuestions=response.data                 
                            })
                            .catch((err)=>{
                                console.log(err)
                            })                      
                        }else if(response.status==202){
                            this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                        }
                    })
                    .catch(err=>{
                        this.$router.push({name:'NoInternet'})
                        console.log(err)
                    })
                }
            }
        },
        save(){
            if(this.$refs.form.validate()){
                if(this.selectedFile!=null){
                 let fd=new FormData()
                 fd.append('image',this.selectedFile)
                 this.$store.dispatch('question/uploadImage',fd)               
                    .then(response=>{
                        if(response.status==200){
                            this.editedItem.imagePath='/'+response.data.dbPath                           
                            this.insertOrUpdateQuestion()
                        }
                    })
                    .catch(err=>{
                        console.log(err)
                    })
                }else{
                    this.insertOrUpdateQuestion()
                }
            }
        },
    },
    computed:{
        formTitle(){
            return this.editedIndex===-1?this.$t('newQuestion'):this.$t('editQuestion')
        },
        chkPerQuestionMarkVisibility(){
            return this.quizObj.quizMarkOptionId==3?true:false
        },
        chkimageOptionVisible(){
            return this.imageOptionVisible
        },
        chkAnswerLengthVisible(){
            return this.isAnswerLengthVisible
        },
        chkCodeSnipetVisible(){
            return this.isCodeSnipetVisible
        },      
        chkUploadedVideoForView(){
            return this.isShowUploadedVideo
        },
        chkAnswerSingleVisibility(){
            return this.quizObj.quizMarkOptionId!=2&&this.quizObj.allowMultipleAnswer==false?true:false
        },
        chkAnswerMultipleVisibility(){
            return this.quizObj.quizMarkOptionId!=2&&this.quizObj.allowMultipleAnswer==true?true:false
        },
        chkAllOptionsVisible(){
            return this.allOptionsVisible
        }
    },
    watch:{
        dialog (val) {         
            val || this.close()
        },
        dialogDelete (val) {
            val || this.closeDelete()
        },
    },
    created(){         
        this.initialize()
        this.getTypeOptions()
    }
}
</script>